<template>
  <div>
    <img src="https://static.jipink.com/base/logo.png" width="100" />
    <p>
      <a href="https://jipink.com" title="Jipink">Jipink</a> &copy; {{(new Date().getFullYear())}}. All Rights Reserved.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
<style scoped>
div {
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: lighter;
  text-align: center;
}
p {
  margin-top: 20px;
}
</style>
