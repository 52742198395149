<template>
  <div>
    <div class="loader" v-if="loading">
      <a-spin size="large" />
    </div>
    <div class="content">
      <transition name="transition" appear>
        <a-space direction="vertical" :size="40">
          <!-- APPROVED -->
          <j-cover
            image="result-success.svg"
            title="¡Listo!"
            v-if="status == 'APPROVED'">
            Tu pago fue aprobado y te enviamos un mail a <code>{{ payment.email }}</code> para que puedas descargar el comprobante. Allí también enviaremos las novedades sobre tu operación.
          </j-cover>
          <!-- PROCESSING -->
          <j-cover
            image="result-pending.svg"
            title="Falta un paso más..."
            v-else-if="status == 'PROCESSING'">
            Tu pago aún está pendiente. Te enviaremos un mail a <code>{{ payment.email }}</code> cuando haya sido aprobado para que puedas descargar el comprobante. Allí también enviaremos las novedades sobre tu operación.
          </j-cover>
          <!-- PENDING -->
          <j-cover
            image="result-pending.svg"
            title="Falta un paso más..."
            v-else-if="status == 'PENDING'">
            Tu pago está siendo procesado. Te enviaremos un mail a <code>{{ payment.email }}</code> cuando haya sido aprobado para que puedas descargar el comprobante. Allí también enviaremos las novedades sobre tu operación.
          </j-cover>
          <!-- FAILURE -->
          <j-cover
            image="result-failure.svg"
            title="Rechazaron tu pago =("
            v-else-if="status == 'REJECTED'">
            Tu pago fue rechazado y no podemos proceder con la operación. Verficá que el método de pago utilizado sea válido y volvé a intentar nuevamente cuando se haya solucionado el inconveniente.
          </j-cover>
          <!-- NOT FOUND -->
          <j-cover
            image="result-empty.svg"
            title="Código inválido"
            v-else-if="!loading">
            No encontramos ningún pago con el código especificado. El código de pago es <code>P**********</code> y te lo enviamos por mail junto al comprobante desde una cuenta @jipink.com.
          </j-cover>
          <a-card
            class="detail"
            v-if="status == 'APPROVED'">
            <j-support :payment="payment" />
          </a-card>
          <j-footer />
        </a-space>
      </transition>
    </div>
  </div>
</template>

<script>
import JCover from './cover'
import JFooter from './footer'
import JSupport from './support'
import axios from 'axios'

export default {
  name: 'Payment',
  components: {
    JCover,
    JFooter,
    JSupport,
  },
  data() {
    return {
      contact: false,
      error: false,
      payment: null,
      loading: false,
    };
  },
  computed: {
    code() {
      return (new URL(document.location)).searchParams.get('code')
    },
    status() {
      return this.payment ? this.payment.status : undefined
    },
  },
  mounted() {
    this.loading = true
    axios.get(`${process.env.VUE_APP_API_URL}/self-service/payments?code=${this.code}`)
    .then(response => (this.payment = response.data))
    .catch(() => (this.error = true))
    .finally(() => (this.loading = false))
  },
}
</script>
<style scoped>
.loader {
  padding: 30px;
}
.content {
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;
}
.detail {
  max-width: 800px;
  margin: auto;
}
.progress {
  padding: 30px;
}
.support {
  margin-left: -41px;
}
.support button{
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.transition-enter-active, .transition-leave-active {
  transition: opacity .8s, transform .8s;
}
.transition-enter {
  opacity: 0;
  transform: translateY(20px)
}
.transition-leave-to {
  opacity: 0;
  transform: translateY(-20px)
}
</style>