<template>
  <div id="app">
    <j-payment />
  </div>
</template>

<script>
import JPayment from '@/views/payment.vue'

export default {
  name: 'App',
  components: {
    JPayment,
  }
}
</script>
