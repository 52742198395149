<template>
  <a-row type="flex" align="middle" justify="center">
    <a-col class="col-1" :xs="24" :sm="8">
      <img src="../assets/download.webp" />
    </a-col>
    <a-col class="col-2" :xs="24" :sm="16">
      <h1>¿No te llegó el mail?</h1>
      <p>Revisá tu casilla de spam para asegurarte que no lo hayas recibido allí. De todas maneras, podés descargar tu comprobante de pago desde acá.</p>
      <a :href="payment.receipt">
        <a-button type="primary" shape="round" size="large">
          Descargar comprobante
        </a-button>
      </a>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Support',
  props: {
    payment: Object
  },
}
</script>
<style scoped>
@media only screen and (max-width: 576px) {
  .col-2 {
    text-align: center;
  }
}
.col-1 {
  text-align: center;
  padding: 20px;
}
.col-1 img {
  width: 50%;
  max-width: 120px;
}
h1 {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 32px;
  line-height: 1;
  color: #303669;
}
p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
</style>
