<template>
  <a-row type="flex" align="middle" justify="center">
    <a-col class="col-1" :xs="24" :sm="14">
      <div class="illustration" >
        <img src="../assets/payment.webp" />
      </div>
    </a-col>
    <a-col class="col-2" :xs="24" :sm="10">
      <h1>{{ title }}</h1>
      <p><slot></slot></p>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Cover',
  props: {
    image: String,
    title: String,
  },
}
</script>
<style scoped>
@media only screen and (max-width: 576px) {
  .col-2 {
    text-align: center;
  }
}
.illustration {
  text-align: center;
}
.illustration img {
  width: 75%;
}
.col-1, .col-2 {
  padding: 20px;
}
h1 {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 60px;
  line-height: 1;
  color: #303669;
}
p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}
</style>
